import React from 'react';
import { Link } from 'gatsby';
import './footer.scss';

const Footer = () => {
  return (
    <div>
      <div className='divider burly-bg'></div>
      <footer className='uk-section uk-section-large burly-bg'>
        <div className='uk-container'>
          <div
            className='uk-grid-margin-large uk-grid align-center-mobile'
            data-uk-grid
          >
            <div className='uk-width-expand@l uk-width-1-3@s uk-first-column'>
              <h4>AMPLE MAINTENANCE</h4>
              <ul className='uk-list'>
                <li>
                  <Link className='white-link' to='/'>
                    Home Page
                  </Link>
                </li>
                <li>
                  <Link className='white-link' to='/quote'>
                    Get A Quote
                  </Link>
                </li>
                {/* <li>
                  <a className='white-link' href='/blog/'>
                    Reviews
                  </a>
                </li> */}
                <li>
                  <Link className='white-link' to='/contact'>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <div className='uk-width-expand@l uk-width-1-3@s'>
              <h4>COMMERCIAL</h4>
              <ul className='uk-list'>
                <li>
                  <Link className='white-link' to='/commercial'>
                    Building
                  </Link>
                </li>
                <li>
                  <Link className='white-link' to='/commercial'>
                    Commerical Spaces
                  </Link>
                </li>
                <li>
                  <Link className='white-link' to='/commercial'>
                    Office
                  </Link>
                </li>
              </ul>
            </div>

            <div className='uk-width-expand@l uk-width-1-3@s'>
              <h4>RESIDENTIAL</h4>
              <ul className='uk-list uk-text-small-'>
                <li>
                  <Link className='white-link' to='/residential'>
                    Apartment
                  </Link>
                </li>
                <li>
                  <Link className='white-link' to='/residential'>
                    Building
                  </Link>
                </li>
                <li>
                  <Link className='white-link' to='/residential'>
                    Home
                  </Link>
                </li>
              </ul>
            </div>

            <div className='uk-width-medium@l- uk-width-1-3@m'>
              <div className='uk-margin'>
                <div
                  data-uk-grid
                  className='uk-child-width-auto uk-grid align-content-center-mobile'
                >
                  {/* Add when Chris creates */}
                  {/* <a
                    href='https://twitter.com/getconduktor'
                    data-uk-icon='icon: facebook'
                    className='black-link'
                    aria-label='Twitter'
                    rel='noopener'
                    target='_blank'
                  ></a>
                  <a
                    href='https://twitter.com/getconduktor'
                    data-uk-icon='icon: twitter'
                    className='black-link'
                    aria-label='Twitter'
                    rel='noopener'
                    target='_blank'
                  ></a>
                  <a
                    href='https://twitter.com/getconduktor'
                    data-uk-icon='icon: linkedin'
                    className='black-link'
                    aria-label='Twitter'
                    rel='noopener'
                    target='_blank'
                  ></a> */}
                  <a
                    href='https://www.instagram.com/amplemaintenance/'
                    data-uk-icon='icon: instagram'
                    className='black-link'
                    aria-label='Instagram'
                    rel='noopener'
                    target='_blank'
                  ></a>
                  <a
                    href='tel:+1-631-496-0164'
                    data-uk-icon='icon: receiver'
                    className='black-link'
                    aria-label='Mail'
                  ></a>
                  <a
                    href='mailto:amplemaintenancenyc@gmail.com'
                    data-uk-icon='icon: mail'
                    className='black-link'
                    aria-label='Mail'
                  ></a>
                </div>
              </div>

              <div className='uk-margin uk-text-small'>
                © 2020 by Ample Maintenance - All Rights Reserved
              </div>
              <div className='uk-margin uk-text-small white'>
                Ample Maintenance is a trusted and experienced building
                maintenance company. We take great pride in our ability to keep
                our client's facilities operating at it's highest capacity. Our
                business is keeping your business up and running seamlessly, so
                you can perform your best. Enjoy the the peace of mind knowing
                you have a competent, honest team managing all your maintenance
                needs.
              </div>
              <div className='uk-margin uk-text-small white mobile-footer'>
                Website built by{' '}
                <a href='mailto:patpetillo@gmail.com' className='white-link'>
                  Pat Petillo
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
