import React from 'react';
import AmpleLogoWhite from 'images/ample-logo-white-horizontal.png';
import './mobileNav.scss';

const MobileNav = () => {
  return (
    <div
      id='nav-mobile'
      data-uk-offcanvas='flip: true; overlay: true'
      className='uk-offcanvas'
    >
      <button uk-toggle='target: #my-id' type='button'></button>
      <div className='uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide'>
        <button
          className='uk-offcanvas-close uk-icon uk-close'
          type='button'
          data-uk-close
        ></button>
        <ul className='uk-nav uk-nav-primary'>
          <li>
            <a
              className='uk-logo uk-margin-small-bottom'
              href='https://amplemaintenance.com'
            >
              <img className='align-logo' src={AmpleLogoWhite} />
            </a>
          </li>

          <li>
            <a href='https://amplemaintenance.com'>Home</a>
          </li>
          <li>
            <a href='https://amplemaintenance.com/contact'>Contact</a>
          </li>
          <li>
            <a href='https://amplemaintenance.com/commercial'>Commercial</a>
          </li>
          <li>
            <a href='https://amplemaintenance.com/residential'>Residential</a>
          </li>
          <li>
            <a href='https://amplemaintenance.com/quote'>Get a Quote</a>
          </li>
        </ul>

        <div className='uk-margin-small-top uk-text-center uk-text-muted uk-link-muted'>
          <div
            data-uk-grid=''
            className='uk-child-width-auto uk-grid-small uk-flex-start uk-grid'
          >
            <a
              href='https://www.instagram.com/amplemaintenance/'
              data-uk-icon='icon: instagram'
              className='black-link'
              aria-label='Instagram'
              rel='noopener'
              target='_blank'
            ></a>
            <a
              href='tel:+1-631-496-0164'
              data-uk-icon='icon: receiver'
              className='black-link'
              aria-label='Mail'
            ></a>
            <a
              href='mailto:amplemaintenancenyc@gmail.com'
              data-uk-icon='icon: mail'
              className='black-link'
              aria-label='Mail'
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
