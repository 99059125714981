import React from 'react';
import AmpleLogoBlack from 'images/ample-logo-black-horizontal.png';
import AmpleLogoWhite from 'images/ample-logo-white-horizontal.png';
import './header.scss';
import { Link } from 'gatsby';

interface Props {
  siteTitle: string;
}

const Header = ({ siteTitle }: Props) => {
  return (
    <header>
      <div
        data-uk-sticky='animation: uk-animation-slide-top; top: 200; sel-target: .outer-container; cls-active: uk-navbar-sticky uk-background-default uk-dark; cls-inactive: uk-navbar-transparent uk-background-secondary uk-light'
        className='uk-sticky'
      >
        <nav className='outer-container min-height'>
          <div className='uk-container'>
            <div data-uk-navbar className='uk-navbar'>
              <div className='uk-navbar-left'>
                <a className='uk-navbar-item uk-logo remove-padding' href='/'>
                  <img
                    className='uk-logo-inverse remove-margin'
                    src={AmpleLogoWhite}
                    height='75'
                    width='200'
                    alt='Conduktor'
                  />

                  <img
                    className='remove-margin'
                    src={AmpleLogoBlack}
                    alt='Conduktor'
                    height='75'
                    width='200'
                  />
                </a>
                <ul className='uk-navbar-nav uk-visible@m'></ul>
              </div>

              <div className='uk-navbar-center'>
                <ul className='uk-navbar-nav uk-visible@m'></ul>
              </div>
              <div className='uk-navbar-right'>
                <ul className='uk-navbar-nav uk-visible@m'>
                  <li>
                    <Link to='/' className='uk-text-bold'>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to='/commercial' className='uk-text-bold'>
                      Commercial
                    </Link>
                  </li>
                  <li>
                    <Link to='/residential' className='uk-text-bold'>
                      Residential
                    </Link>
                  </li>
                  <li>
                    <Link to='/quote' className='uk-text-bold'>
                      Get a Quote
                    </Link>
                  </li>
                  {/* <li>
                    <Link to='/reviews' className='uk-text-bold'>
                      Reviews
                    </Link>
                  </li> */}
                  <li>
                    <div className='uk-navbar-item'>
                      <Link
                        to='/contact'
                        className='uk-button uk-button-primary-preserve uk-text-bold'
                      >
                        Contact
                      </Link>
                    </div>
                  </li>
                </ul>

                <a
                  className='uk-navbar-toggle uk-hidden@m uk-icon uk-navbar-toggle-icon'
                  href='#nav-mobile'
                  aria-label='Mobile Navigation'
                  data-uk-navbar-toggle-icon=''
                  data-uk-toggle=''
                >
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                    data-svg='navbar-toggle-icon'
                  >
                    <rect y='9' width='20' height='2'></rect>
                    <rect y='3' width='20' height='2'></rect>
                    <rect y='15' width='20' height='2'></rect>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div
        className='uk-sticky-placeholder'
        style={{ height: '124px', margin: '0px' }}
        hidden
      ></div>
    </header>
  );
};

export default Header;
